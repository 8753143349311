export function getBrowserLanguage(langs) {
    let browserLanguage = (
        navigator.language ||
        navigator.browserLanguage
    );

    if (!langs.find(l => l.language === browserLanguage)) {
        browserLanguage = 'en';
    }

    return browserLanguage;
}
