import * as DOMPurify from 'dompurify';

const fullNames = {
    en: 'English',
    es: 'Español',
    he: 'עִברִית',
};

export function appendData(title, description) {
    document.getElementById('title').innerText = (title || '');
    document.getElementById('description').innerHTML = DOMPurify.sanitize(description || '');
}

export function appendOption(lang, selected) {
    const option = document.createElement('option');

    option.value = lang.language;
    option.innerText = fullNames[lang.language];

    if (selected) {
        option.setAttribute('selected', '');
    }

    document.getElementById('select-language').appendChild(option);
}

export function toRtl(lang) {
    if (lang === 'he') {
        document.body.classList.add('rtl');
        return;
    }

    document.body.classList.remove('rtl');
}
