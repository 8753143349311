import '../../node_modules/quill/dist/quill.core.css';
import '../styles/index.scss';

import {apiUrl} from './env';
import Jsona from 'jsona';
import {appendData, appendOption, toRtl} from './dom';
import {getBrowserLanguage} from './utils';

const dataFormatter = new Jsona();
const privacyUrl = `${apiUrl}/v4/privacy-policy/one?withPrivacyPolicyVersionLangs=1`;
const termsUrl = `${apiUrl}/v4/terms/one?withTermsVersionLangs=1`;

getPolicy();
getTerms();

const select = document.getElementById('select-language');
const formSelect = document.getElementById('form-select');

function onLanguageChange(selectedLanguages) {
    select.addEventListener('change', function () {
        const lang = selectedLanguages.find(l => l.language === this.value);

        if (lang) {
            toRtl(lang.language);
            appendData(lang.title, lang.description);
        }
    });
}

function getPolicy() {
    if (!window.location.pathname.includes('privacy-policy')) {
        return;
    }

    fetch(privacyUrl)
        .then(response => response.json())
        .then(json => dataFormatter.deserialize(json))
        .then((res) => {
            if (res.PrivacyPolicyVersionLang) {
                setDefaultLanguage(res.PrivacyPolicyVersionLang);
            }
        })
        .catch(err => console.error(err));
}

function getTerms() {
    if (!window.location.pathname.includes('website-app-terms-of-service')) {
        return;
    }

    fetch(termsUrl)
        .then(response => response.json())
        .then(json => dataFormatter.deserialize(json))
        .then((res) => {
            if (res.TermsVersionLang) {
                setDefaultLanguage(res.TermsVersionLang);
            }
        })
        .catch(err => console.error(err));
}

function setDefaultLanguage(langs) {
    const selectedLanguages = langs.filter(l => l.title && l.description);
    let currentLanguage = 'en';

    if (selectedLanguages.length > 1) {
        currentLanguage = getBrowserLanguage(selectedLanguages);
        formSelect.style.display = 'inline-block';
        select.value = currentLanguage;

        selectedLanguages.forEach((lang) => appendOption(lang, lang.language === currentLanguage));

        onLanguageChange(selectedLanguages);
    }

    const lang = langs.find(l => l.language === currentLanguage);

    if (lang) {
        toRtl(lang.language);
        appendData(lang.title, lang.description);
    }
}
