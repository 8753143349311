let apiUrl = '';

const origin = window.location.origin;

const local = 'http://localhost:8080';
const dev = 'https://dev.habitu.health';
const stage = 'https://stage.habitu.health';
const prod = 'https://habitu.health';

switch (origin) {
    case local:
    case dev:
        apiUrl = 'https://api-dev.habitu.health';
        break;
    case stage:
        apiUrl = 'https://api-stage.habitu.health';
        break;
    case prod:
        apiUrl = 'https://api.habitu.health';
        break;
}

export {
    apiUrl
};
